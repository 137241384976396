@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "AcuminPro-Regular";
  src: url("/src/assets/fonts/Acumin-RPro.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "AcuminPro-Bold";
  src: url("/src/assets/fonts/Acumin-BdPro.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Eina02-Regular";
  src: url("/src/assets/fonts/Eina02-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Eina02-SemiBold";
  src: url("/src/assets/fonts/Eina02-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Eina02-Bold";
  src: url("/src/assets/fonts/Eina02-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Inter-Regular";
  src: url("/src/assets/fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("/src/assets/fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("/src/assets/fonts/Inter-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("/src/assets/fonts/Inter-Bold.ttf") format("truetype");
}
/* Add this in your CSS file */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.notification-new {
  animation: fade-in 0.5s ease-out;
}
/* Add this to your CSS file */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.notification-pulse {
  animation: pulse 1s infinite;
}
/* Add this in your CSS file */
.notification-badge {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: white;
}
/* Add these styles to your CSS file */
.notification-dropdown {
  width: 300px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 100;
}

.notification-item {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid #f0f0f0;
}

.notification-item:last-child {
  border-bottom: none;
}

.notification-item:hover {
  background-color: #f9f9f9;
}

.notification-message {
  flex-grow: 1;
  margin-left: 8px;
}

.notification-badge {
  position: absolute;
  top: 0px;
  right: 30px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.notification-icon {
  width: 27px;
  height: 24px;
  border-radius: 50%;
  background-color: #ddd;
}
